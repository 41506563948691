.big-subtitle {
    font-weight: 400;
    text-align: center;
    color: #8D919E;
    margin: 1em 6em;
    flex: auto;
}

/* .in-section.big {
    margin: 6em 0;
} */

.in-section.gradient {
    background: linear-gradient(180deg, rgba(248, 248, 250, 0) 0%, #F8F8FA 100%);
    width: 120%;
    align-self: center;
    padding-right: 4rem;
    height: 85vh;
}

.in-section.fit-smaller {
    height: 70vh;
}

@media screen and (max-width:850px) {
    .big-subtitle {
        margin: 1em;
    }

    .in-section.gradient {
        width: 100%;
        align-self: center;
        padding-right: 10px;
        background: transparent;
        height: fit-content;
    }

    .background-gradient {
        background: linear-gradient(180deg, rgba(60, 113, 251, 0) 100%, #3C71FB 0%);
        opacity: 0.1;
        position: absolute;
    }

    .in-section.fit-smaller {
        height: auto;
        margin-top: 0;
    }

}