.App {
  width: 100%;
  height: 100%;
  padding-top: 3%;
}

* {
  box-sizing: border-box;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: normalText;
  src: local('normalText'), url('assets/fonts/HelveticaNeue.ttf') format('ttf');
}

@font-face {
  font-family: mediumText;
  src: local('mediumText'),
    url('assets/fonts/HelveticaNeue\ Medium.ttf') format(('ttf'));
}

html {
  scroll-behavior: smooth;
  font-size: 16px;
}

.subtitle {
  font-size: 0.9rem;
}

.small-title {
  font-size: 2rem;
  font-family: 'Manrope-ExtraBold';
  color: #0c121e;
}

.side-menu-text {
  font-size: 2rem;
  font-family: 'Manrope-Bold';
}

.description {
  font-size: 0.9rem;
}

.member-name {
  font-size: 1.125rem;
}

.title {
  font-size: 2.5rem;
  font-family: 'Manrope-ExtraBold';
}

.white {
  color: white;
}

p {
  font-size: 1rem;
}

.center {
  justify-content: center;
}

@media (min-width: 0px) and (max-width: 850px) {
  .ellipse {
    position: absolute;
    width: 15vh;
    height: 20vh;

    background: #4d7bf1;
    filter: blur(20vh);
    -webkit-backdrop-filter: blur(20vh);
    backdrop-filter: blur(20vh);
    -moz-backdrop-filter: blur(20vh);
    /* z-index: -1000; */
    overflow: hidden !important;
  }

  .title {
    font-size: 2rem;
  }

  .button-text {
    font-size: 1rem;
    font-family: 'Manrope-Medium';
  }
}

@media (min-width: 1001px) {
  .ellipse {
    position: absolute;
    width: 15vw;
    height: 20vw;

    background: #4d7bf1;
    filter: blur(15vw);
    z-index: -1000;
    overflow: hidden !important;
  }
}

@media screen and (max-width: 500px) {
  * {
    text-align: center !important;
  }
}

.row {
  --bs-gutter-x: 0;
}

.col {
  margin: 0 1rem;
}
