@font-face {
  font-family: 'Manrope-Bold';
  src: url('./assets/fonts/Manrope-Bold.ttf');
}

@font-face {
  font-family: 'Manrope-ExtraBold';
  src: url('./assets/fonts/Manrope-ExtraBold.ttf');
}

@font-face {
  font-family: 'Manrope-Light';
  src: url('./assets/fonts/Manrope-Light.ttf');
}

@font-face {
  font-family: 'Manrope-Medium';
  src: url('./assets/fonts/Manrope-Medium.ttf');
}

@font-face {
  font-family: 'Manrope-Regular';
  src: url('./assets/fonts/Manrope-Regular.ttf');
}

html {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
}

html,
body {
  contain: paint;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

html {
  overflow-y: scroll;
}

/* Hide scrollbar for Chrome, Safari and Opera */
html::-webkit-scrollbar {
  width: 0;
  display: none;
}

body::-webkit-scrollbar {
  width: 0;
  display: none;
}

body {
  position: relative;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif, 'Manrope';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background: linear-gradient(to top,
      black 0%,
      black 30%,
      white 30%,
      white 45%,
      black 45%,
      black 100%); */
  color: white;
  margin: 0;
  /* overflow-x: hidden; */
  display: flex;
  flex-direction: column;
  justify-content: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  font-family: 'Manrope-Regular';
}

.f-bold {
  font-family: 'Manrope-Bold';
}

.f-medium {
  font-family: 'Manrope-Medium';
}

.f-regular {
  font-family: 'Manrope-Regular';
}

.f-light {
  font-family: 'Manrope-Light';
}

.in-section {
  height: 100vh;
}

@media screen and (max-width: 850px) {
  .in-section {
    height: auto;
  }
}
